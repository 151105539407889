<template>
  <div :class="classInput">
    <div class="form-group">
      <label class="label" :class="{'required': isRequired}">{{ label }}</label>
      <select
        class="form-control"
        :class="{'is-invalid': isInvalid}"
        @change="$emit('input', $event.target.value)"
        @blur="() => validate()"
      >
        <option></option>
        <option
          v-for="opt in options"
          :key="opt.value"
          :value="opt.value"
          :selected="value === opt.value"
          >
          {{ opt.label || 'No label' }}
        </option>
      </select>
      <div
        v-if="isInvalid"
        class="invalid-feedback"
      >
        <span v-if="isInvalid">Campo obrigatório.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true,
      validator (opts) {
        return !opts.find(opt => typeof opt !== 'object')
      }
    },
    value: {
      type: String,
      required: true
    },
    classInput: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isInvalid: false,
      hasTouched: false
    }
  },
  methods: {
    validate () {
      this.isInvalid = (this.hasTouched && this.value === null) || (this.isRequired && !this.value)
    },
    isValid () {
      this.validate()
      return !this.isInvalid
    }
  }
}
</script>
